import { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { Tooltip } from '@mui/material';

import Video from 'src/components/Video';

import { MEDIA_TYPE } from '../constants';

const PREFIX = 'ThirdPartyMediaTile';

const classes = {
  container: `${PREFIX}-container`,
  asset: `${PREFIX}-asset`,
  popperTop: `${PREFIX}-popperTop`
};

const StyledTooltip = styled(Tooltip)(() => ({
  [`& .${classes.container}`]: {
    position: 'relative',
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },

  [`& .${classes.asset}`]: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },

  [`& .${classes.popperTop}`]: {
    top: '13px'
  }
}));

const ThirdPartySearch = ({ media, mediaType, tooltip, clipBottom }) => {
  const videoRef = useRef(null);
  return (
    <StyledTooltip
      arrow
      placement="top"
      classes={{
        tooltipPlacementTop: classes.popperTop
      }}
      title={tooltip || ''}
    >
      <div
        data-cy="media-search-result"
        onMouseEnter={() => {
          if (mediaType === MEDIA_TYPE.video) {
            videoRef.current.play();
          }
        }}
        onMouseLeave={() => {
          if (mediaType === MEDIA_TYPE.video) {
            videoRef.current.pause();
          }
        }}
        className={classes.container}
      >
        {mediaType === MEDIA_TYPE.video && (
          <Video
            videoRef={videoRef}
            alt={media.name || media.id}
            src={media.previewUrl}
            preload="metadata"
            className={classes.asset}
            loop
          />
        )}
        {mediaType === MEDIA_TYPE.image && (
          <>
            <img
              src={media.previewUrl}
              className={classes.asset}
              alt={media.name || media.id}
              style={{
                clipPath: `inset(0px 0px ${clipBottom || '0px'} 0px)`
              }}
              onContextMenu={e => {
                // Prevent the context menu from showing
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </>
        )}
      </div>
    </StyledTooltip>
  );
};

export default flow()(ThirdPartySearch);

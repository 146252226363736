import { styled } from '@mui/material/styles';
import CardMedia from './DisplayCardMedia';
import { getPreviewWidth } from '../../helpers';
import { SQUARE_AD_PREVIEW_WIDTH } from '../GoogleAdPreviewConstants';

const PREFIX = 'DisplaySquareAd';

export const classes = {
  container: `${PREFIX}-container`,
  wrapper: `${PREFIX}-wrapper`,
  cardContainer: `${PREFIX}-cardContainer`,
  filler: `${PREFIX}-filler`,
  placeholderText: `${PREFIX}-placeholderText`,
  fakeTextContainer: `${PREFIX}-fakeTextContainer`,
  profileContainer: `${PREFIX}-profileContainer`,
  fakeImage: `${PREFIX}-fakeImage`,
  placeholderTextShort: `${PREFIX}-placeholderTextShort`,
  contentContainer: `${PREFIX}-contentContainer`
};

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: props => ({
    background: '#fff',
    border: '1px solid #dadce0',
    borderBottomWidth: '0',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'start',
    width: getPreviewWidth(
      props.hasUniformPreviewWidth,
      SQUARE_AD_PREVIEW_WIDTH,
      props.isResponsive
    )
  }),

  [`& .${classes.wrapper}`]: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },

  [`& .${classes.cardContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0',
    width: '100%'
  },

  [`& .${classes.filler}`]: {
    backgroundColor: '#5f6368',
    height: '200px',
    opacity: '.26',
    width: '360px'
  },

  [`& .${classes.placeholderText}`]: {
    background: theme.palette.grey[200],
    height: theme.spacing(1),
    margin: '5px 0',
    width: '100%'
  },

  [`& .${classes.fakeTextContainer}`]: {
    padding: `${theme.spacing(1)}`,
    width: '100%'
  },

  [`& .${classes.profileContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    padding: `${theme.spacing(1)}`,
    width: '100%'
  },

  [`& .${classes.fakeImage}`]: {
    backgroundColor: '#9aa0a6',
    borderRadius: '16px',
    height: '16px',
    width: '16px',
    marginRight: '10px'
  },

  [`& .${classes.placeholderTextShort}`]: {
    background: theme.palette.grey[200],
    height: theme.spacing(1),
    margin: '5px 0',
    width: '20%'
  },

  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    height: '296px',
    width: '300px'
  }
}));

export {};

const DisplaySquareAd = props => {
  const { squareImageUrl, headline, description, businessName } = props;
  return (
    <Root className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.fakeTextContainer}>
          <div className={classes.placeholderText} />
          <div className={classes.placeholderText} />
          <div className={classes.placeholderText} />
        </div>
        <div className={classes.profileContainer}>
          <div className={classes.fakeImage} />
          <div className={classes.placeholderTextShort} />
        </div>
      </div>
      <CardMedia
        headline={headline}
        description={description}
        businessName={businessName}
        image={squareImageUrl}
      />
    </Root>
  );
};

export default DisplaySquareAd;

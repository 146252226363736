import { flow } from 'lodash';

import { styled } from '@mui/material/styles';

import { Theme, Typography } from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

import { Box } from '@mui/system';

const PREFIX = 'Message';

const classes = {
  container: `${PREFIX}-container`,
  childContainer: `${PREFIX}-childContainer`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  info: `${PREFIX}-info`,
  warn: `${PREFIX}-warn`,
  error: `${PREFIX}-error`
};

const StyledBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.container}`]: {
    maxWidth: '450px',
    padding: theme.spacing(2)
  },

  [`& .${classes.childContainer}`]: {
    width: '100%',
    padding: '5px'
  },

  [`& .${classes.small}`]: {
    width: '20px',
    height: '20px'
  },

  [`& .${classes.medium}`]: {
    width: '35px',
    height: '35px'
  },

  [`& .${classes.large}`]: {
    width: '50px',
    height: '50px'
  },

  [`& .${classes.info}`]: {},

  [`& .${classes.warn}`]: {
    color: theme.palette.warning.main
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.dark
  }
}));

const ICON_TYPES = {
  error: ErrorIcon,
  info: InfoIcon,
  warn: ErrorIcon
} as const;

type MessageType = keyof typeof ICON_TYPES;

interface MessageProps {
  className?: string;
  children: JSX.Element | string;
  iconSize: 'small' | 'medium' | 'large';
  type: MessageType;
  maxWidth?: string;
  sx?: Record<string, unknown>;
}

const Message = (props: MessageProps) => {
  const {
    children,
    iconSize = 'large',
    type = 'info',
    maxWidth = '100%',
    sx
  } = props;

  const colorClass = classes[type] ? classes[type] : classes.info;

  const IconComponent = ICON_TYPES[type] ? ICON_TYPES[type] : ICON_TYPES.info;

  const iconClass = classes[iconSize] ? classes[iconSize] : classes.large;

  const iconComponent = (
    <IconComponent className={`${colorClass} ${iconClass}`} />
  );

  return (
    <StyledBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1.5,
        whiteSpace: 'normal !important',
        maxWidth,
        ...sx
      }}
    >
      {iconComponent}
      <Typography className={colorClass} variant="body2">
        {children}
      </Typography>
    </StyledBox>
  );
};

export default flow()(Message);

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { STYLES } from '../GoogleAdPreviewConstants';

const PREFIX = 'DisplayCardMedia';

const classes = {
  bunsinessNameText: `${PREFIX}-bunsinessNameText`,
  imageContainer: `${PREFIX}-imageContainer`,
  adBadge: `${PREFIX}-adBadge`,
  headline: `${PREFIX}-headline`,
  headlineText: `${PREFIX}-headlineText`,
  footerContainer: `${PREFIX}-footerContainer`
};

const StyledCard = styled(Card)(() => ({
  [`& .${classes.bunsinessNameText}`]: {
    color: '#3c4043',
    fontSize: '0.685rem',
    paddingLeft: '6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: '1'
  },

  [`& .${classes.imageContainer}`]: {
    maxWidth: '300px'
  },

  [`& .${classes.adBadge}`]: {
    fontWeight: 'bold',
    fontSize: '0.685rem'
  },

  [`& .${classes.headline}`]: {
    display: 'box',
    lineHeight: '110%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxOrient: 'vertical',
    lineClamp: '3'
  },

  [`& .${classes.headlineText}`]: {
    fontSize: '0.8rem',
    fontFamily: STYLES.fontFamily.googleSans,
    color: '#202124',
    letterSpacing: '0.008rem'
  },

  [`& .${classes.footerContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '12px',
    marginTop: '12px',
    marginBottom: '12px'
  }
}));

const CardMedia = ({ headline, description, image, businessName }) => {
  return (
    <StyledCard sx={{ margin: '8px', minWidth: '300px' }}>
      {image && <div className={classes.imageContainer}>{image}</div>}
      <CardContent>
        <div className={classes.headline}>
          <span className={classes.headlineText}>
            {headline} | {description}
          </span>
        </div>
        <div className={classes.footerContainer}>
          <div className={classes.adBadge}>Ad • </div>
          <div className={classes.bunsinessNameText}>{businessName}</div>
        </div>
      </CardContent>
    </StyledCard>
  );
};

export default CardMedia;

import { useState, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { flow, values } from 'lodash';
import { FormSection, Field } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Box
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { DynamicForm } from 'src/components/ReduxForm';
import { useSnackbar } from 'notistack';
import {
  getInitialValuesFromInputsConfig,
  configureInputs
} from 'src/components/ReduxForm/helpers';
import RenderOrgWebhookSwitch from './RenderOrgWebhookSwitch';
import { mapEnumeratedValues } from '../helpers';
import { getEnumeratedValues } from '../queries';
import { getVariablesInputs } from '../Constants';

const PREFIX = 'RenderVariablesInputs';

const classes = {
  contentContainer: `${PREFIX}-contentContainer`,
  contentContainerModal: `${PREFIX}-contentContainerModal`,
  list: `${PREFIX}-list`,
  addNewListItem: `${PREFIX}-addNewListItem`,
  inputContainer: `${PREFIX}-inputContainer`,
  inputClose: `${PREFIX}-inputClose`,
  emptySelection: `${PREFIX}-emptySelection`,
  removeContainer: `${PREFIX}-removeContainer`,
  removeDivider: `${PREFIX}-removeDivider`,
  removeButton: `${PREFIX}-removeButton`,
  listItemAdd: `${PREFIX}-listItemAdd`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    marginTop: theme.spacing(2),
    height: '770px',
    overflow: 'hidden'
  },

  [`& .${classes.contentContainerModal}`]: {
    display: 'flex',
    marginTop: theme.spacing(2),
    height: '600px',
    overflow: 'hidden'
  },

  [`& .${classes.list}`]: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    height: '100%',
    overflowY: 'scroll',
    minWidth: '250px',
    paddingTop: '50px'
  },

  [`& .${classes.addNewListItem}`]: {
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },

  [`& .${classes.inputContainer}`]: {
    marginLeft: '12px',
    height: '100%',
    overflowY: 'scroll'
  },

  [`& .${classes.inputClose}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },

  [`& .${classes.emptySelection}`]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    width: '100%'
  },

  [`& .${classes.removeContainer}`]: {
    display: 'flex',
    justifyContent: 'end'
  },

  [`& .${classes.removeDivider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.removeButton}`]: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main
    }
  },

  [`& .${classes.listItemAdd}`]: {
    background: 'white',
    position: 'absolute',
    width: '240px',
    left: 0,
    zIndex: 1,

    '&:hover': {
      background: theme.palette.grey[100]
    }
  }
}));

const newVariable = {
  ...getInitialValuesFromInputsConfig(getVariablesInputs()),
  array: null
};

const enumInputs = {
  type: 'BLUEPRINT_VARIABLE_TYPES'
};

const pageText = ({ friendlyName }) => ({
  addButton: t('admin:blueprintBuilder.stepVariablesAddButton'),
  removeButton: t('admin:blueprintBuilder.stepVariablesRemoveButton'),
  undoButton: t('admin:blueprintBuilder.stepVariablesUndoButton'),
  removedSuccessMessage: t(
    'admin:blueprintBuilder.stepVariablesRemoveSuccessSnackMessage',
    {
      friendlyName
    }
  ),
  emptySelectionHeading: t(
    'admin:blueprintBuilder.stepVariablesEmptySelectionHeading'
  )
});

const RenderVariablesInputs = props => {
  const { fields, inModal, enumerationValues } = props;
  const [selectedVariable, setSelectedVariable] = useState(null);
  const variablesInputs = getVariablesInputs();
  const { enqueueSnackbar } = useSnackbar();

  const text = useMemo(
    () =>
      pageText({
        friendlyName:
          selectedVariable && fields.get(selectedVariable.index)?.friendlyName
      }),
    [selectedVariable, fields]
  );

  const updatedVariablesInputsUpdated = useMemo(() => {
    return configureInputs({
      inputs: variablesInputs,
      enumInputs,
      enumerationValues
    });
  }, [variablesInputs, enumerationValues]);

  const addNewVariable = useCallback(() => {
    fields.unshift(newVariable);
    setSelectedVariable({
      field: `${fields.name}[0]`,
      index: 0
    });
  }, [fields]);

  const removeVariable = useCallback(() => {
    const removedVariableData = fields.get(selectedVariable.index);
    const removedVariableSelect = selectedVariable;
    fields.remove(selectedVariable.index);
    setSelectedVariable(null);

    enqueueSnackbar(text.removedSuccessMessage, {
      variant: 'success',
      persist: false,
      action: (
        <>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              fields.push(removedVariableData);
              setSelectedVariable(removedVariableSelect);
            }}
          >
            {text.undoButton}
          </Button>
        </>
      )
    });
  }, [selectedVariable, fields]);

  return (
    <Root>
      <ListItem className={classes.listItemAdd} button onClick={addNewVariable}>
        <ListItemText
          className={classes.addNewListItem}
          primary={
            <>
              {text.addButton} <AddIcon />
            </>
          }
        />
      </ListItem>
      <div
        className={
          inModal ? classes.contentContainerModal : classes.contentContainer
        }
      >
        <List className={classes.list}>
          {fields.map((field, index) => {
            return (
              <ListItem
                key={field}
                button
                selected={selectedVariable?.field === field}
                onClick={() =>
                  selectedVariable?.field === field
                    ? setSelectedVariable(null)
                    : setSelectedVariable({ field, index })
                }
              >
                <ListItemText
                  primary={fields.get(index)?.friendlyName}
                  secondary={
                    <>
                      name: <strong>{fields.get(index)?.name}</strong>
                      <br />
                      type: <strong>{fields.get(index)?.type}</strong>
                    </>
                  }
                />
              </ListItem>
            );
          })}
        </List>
        {selectedVariable?.field ? (
          <div className={classes.inputContainer}>
            <div className={classes.inputClose}>
              <IconButton
                onClick={() => setSelectedVariable(null)}
                size="small"
                color="primary"
              >
                <CloseIcon />
              </IconButton>
            </div>

            <Divider className={classes.removeDivider} />

            <div className={classes.removeContainer}>
              <Button
                className={classes.removeButton}
                variant="outlined"
                onClick={removeVariable}
                startIcon={<RemoveIcon />}
              >
                {text.removeButton}
              </Button>
            </div>

            <FormSection name={selectedVariable?.field}>
              <DynamicForm inputs={updatedVariablesInputsUpdated} />
              <Box mt={2}>
                <Field
                  name="includeInWebhooks"
                  component={RenderOrgWebhookSwitch}
                  label="Include In Webhooks"
                  fullWidth
                />
              </Box>
            </FormSection>
          </div>
        ) : (
          <div className={classes.emptySelection}>
            <Typography variant="h6" align="center">
              {text.emptySelectionHeading}
            </Typography>
            <br />
            <Button
              color="primary"
              onClick={addNewVariable}
              startIcon={<AddIcon />}
            >
              {text.addButton}
            </Button>
          </div>
        )}
      </div>
    </Root>
  );
};

export default flow(
  graphql(getEnumeratedValues, {
    name: 'getEnumeratedValues',
    options: () => {
      return {
        variables: {
          request: { enumerations: values(enumInputs) }
        }
      };
    },
    props: mapEnumeratedValues
  })
)(RenderVariablesInputs);

import { forwardRef } from 'react';
import { kebabCase, isFunction } from 'lodash';
import {
  InputAdornment,
  Tooltip,
  FormControl,
  InputLabel,
  OutlinedInput,
  useTheme
} from '@mui/material';
import NumberFormat from 'react-number-format';

import HelpIcon from '@mui/icons-material/HelpOutline';

import { HelperTextFooter } from 'src/components/ReduxForm/HelperTextFooter';
import { ErrorFooter } from 'src/components/ReduxForm/ErrorFooter';

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  return <NumberFormat {...props} getInputRef={ref} />;
});

const RenderTemplateField = props => {
  const {
    input,
    meta: { touched, error },
    tooltip,
    helperText,
    type,
    label,
    inputProps,
    startAdornment,
    margin = 'normal',
    disabled,
    sx
  } = props;

  const theme = useTheme();
  let sxStyles = sx;

  if (isFunction(sx)) {
    sxStyles = sx(theme);
  }

  const { onChange, name, value, ...inputRest } = input;

  const inputInError = error && touched;

  const deDotName = kebabCase(name);
  return (
    <FormControl
      variant="outlined"
      margin={margin}
      fullWidth
      disabled={disabled}
    >
      <InputLabel
        htmlFor={`template-${deDotName}`}
        variant="outlined"
        error={!!inputInError}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id={`template-${deDotName}`}
        inputComponent={NumberFormatCustom}
        value={value}
        onChange={onChange}
        error={!!inputInError}
        label={label}
        fullWidth
        inputProps={inputProps}
        sx={sxStyles}
        {...{
          ...(type !== 'hidden' &&
            tooltip && {
              endAdornment: (
                <InputAdornment
                  sx={{
                    color: 'grey.500',
                    position: 'absolute',
                    top: '13px',
                    right: '3px'
                  }}
                  position="end"
                >
                  <Tooltip arrow title={tooltip}>
                    <HelpIcon sx={{ width: '20px' }} />
                  </Tooltip>
                </InputAdornment>
              )
            }),
          ...(type !== 'hidden' &&
            startAdornment && {
              startAdornment: (
                <InputAdornment
                  disablePointerEvents // This allows the adornment to focus the input on click
                  position="start"
                >
                  {startAdornment}
                </InputAdornment>
              )
            })
        }}
        {...inputRest}
      />
      {inputInError && <ErrorFooter touched={touched} error={error} />}
      {helperText && (
        <HelperTextFooter helperText={helperText} stacked={inputInError} />
      )}
    </FormControl>
  );
};

export default RenderTemplateField;

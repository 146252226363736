import { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { flow, trim, split } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { useSnackbar } from 'notistack';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Typography, Paper, Button, Tooltip, Divider } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import Loading from 'src/components/Loading';

import CatalogForm from './CatalogForm';
import CollapseKeyForm from './CollapseKeyForm';
import ImplicitFilterList from './ImplicitFilterList';
import ContentSetForm from './ContentSetForm';
import FieldMetaList from './FieldMetaList';
import { getContentTableColumns } from './queries';
import { activateCatalog, deactivateCatalog } from './mutations';
import { formatContentTableColumns } from './helpers';

const PREFIX = 'CatalogDetails';

const classes = {
  catalogWrap: `${PREFIX}-catalogWrap`,
  architectureSelector: `${PREFIX}-architectureSelector`,
  active: `${PREFIX}-active`,
  inActive: `${PREFIX}-inActive`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.catalogWrap}`]: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.architectureSelector}`]: {
    marginBottom: theme.spacing(2),
    maxWidth: '350px'
  },

  [`& .${classes.active}`]: {
    color: theme.palette.success[500]
  },

  [`& .${classes.inActive}`]: { color: theme.palette.error.main }
}));

// these two items are stored as templates but we enter them as template keys
// displayImageKey {{ key_fart }} we edit "key_fart"
// displayNameKeys ["{{ key }}, {{ some_thing }}"] we edit ['key', 'some_thing']
const convertTemplateInputs = catalog => {
  // trim the templates
  const displayImageKey = trim(catalog?.displayImageTemplate, ' {}');
  const displayNameKeys = split(catalog?.displayNameTemplate, ',').map(key =>
    trim(key, ' {}')
  );

  return {
    ...catalog,
    displayImageKey,
    displayNameKeys
  };
};

const CatalogDetails = ({
  catalog,
  refetch,
  tableNames,
  selectedArchitecture,
  getContentTableColumns: { loading, contentTableMetadata },
  activateCatalog,
  deactivateCatalog
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const convertedCatalogInitialValues = convertTemplateInputs(catalog);

  // tables are saved as an object on the back end for some reason.
  // so we pull those out to populate the form
  const { table: contentSetTable, accessTable: contentSetAccessTable } =
    catalog?.contentSet?.parameters;

  const { isAccessTableControlled } = catalog?.contentSet;

  // Only populate the form with accessTable and writeTable if
  // the content set is access table controlled
  const contentSetInitalValues = {
    catalogId: catalog.id,
    ...catalog?.contentSet,
    ...(contentSetTable && { contentSetTable }),
    ...(contentSetAccessTable &&
      isAccessTableControlled && { contentSetAccessTable })
  };

  const setCatalog = useCallback(
    async (id, isActive = true) => {
      try {
        if (isActive) {
          await deactivateCatalog({
            variables: {
              architectureId: selectedArchitecture?.id,
              catalogId: id
            }
          });
        } else {
          await activateCatalog({
            variables: {
              architectureId: selectedArchitecture?.id,
              catalogId: id
            }
          });
        }

        enqueueSnackbar(t('admin:catalogContent.catalogActivateSuccess'), {
          variant: 'success'
        });
        refetch();
      } catch (error) {
        // error
        enqueueSnackbar(t('admin:catalogContent.catalogActivateError'), {
          variant: 'error'
        });
        refetch();
      }
    },
    [activateCatalog, deactivateCatalog, selectedArchitecture]
  );

  if (loading) {
    return <Loading />;
  }

  const contentTableColumns = formatContentTableColumns(contentTableMetadata);

  return (
    <Root>
      <Paper className={classes.catalogWrap} key={catalog.id}>
        <Typography variant="body1" style={{ paddingBottom: '10px' }}>
          {catalog?.isActive ? (
            <span className={classes.active}>
              <CheckIcon fontSize="inherit" /> Active
            </span>
          ) : (
            <span className={classes.inActive}>
              <BlockIcon fontSize="inherit" /> Inactive
            </span>
          )}{' '}
          <Button
            onClick={() => setCatalog(catalog.id, catalog?.isActive)}
            size="small"
            variant="outlined"
          >
            {catalog?.isActive ? 'Deactivate' : 'Activate'}
          </Button>
        </Typography>
        <Divider />
        <CatalogForm
          form={`catalogForm${catalog.id}`}
          initialValues={convertedCatalogInitialValues}
          tableNames={tableNames}
          contentTableColumns={contentTableColumns}
          refetch={refetch}
        />
      </Paper>
      <Paper className={classes.catalogWrap}>
        <Tooltip title={t('admin:catalogContent.collapseKeyHelp')}>
          <Typography component="h2" variant="h6">
            {catalog.friendlyName}{' '}
            <Trans i18nKey="admin:catalogContent.collapseKeyHeading">
              Catalog Collapse Key
            </Trans>
            <HelpOutlineIcon />
          </Typography>
        </Tooltip>

        <CollapseKeyForm
          form={`collapseKey${catalog.id}`}
          initialValues={convertedCatalogInitialValues}
          catalog={catalog}
          refetch={refetch}
        />
      </Paper>
      <Paper className={classes.catalogWrap}>
        <Typography component="h2" variant="h6">
          {catalog.friendlyName}{' '}
          <Trans i18nKey="admin:catalogContent.implicitFilterHeading">
            Catalog Implicit Filter
          </Trans>
        </Typography>
        <ImplicitFilterList catalog={catalog} refetch={refetch} />
      </Paper>
      <Paper className={classes.catalogWrap}>
        <Typography component="h2" variant="h6">
          {catalog.friendlyName}{' '}
          <Trans i18nKey="admin:catalogContent.contentSetHeading">
            Catalog Content Set
          </Trans>{' '}
          id: {catalog?.contentSet?.id}
        </Typography>
        <ContentSetForm
          form={`contentSet${catalog.id}`}
          initialValues={contentSetInitalValues}
          tableNames={tableNames}
          refetch={refetch}
        />
      </Paper>
      <Paper className={classes.catalogWrap}>
        <Typography component="h2" variant="h6">
          {catalog.friendlyName}{' '}
          <Trans i18nKey="admin:catalogContent.fieldMetaHeading">
            Field Metadata
          </Trans>
          :
        </Typography>
        <FieldMetaList
          catalog={catalog}
          contentTableColumns={contentTableColumns}
          refetch={refetch}
        />
      </Paper>
    </Root>
  );
};

export default flow(
  graphql(activateCatalog, {
    name: 'activateCatalog'
  }),
  graphql(deactivateCatalog, {
    name: 'deactivateCatalog'
  }),
  graphql(getContentTableColumns, {
    name: 'getContentTableColumns',
    options: props => {
      return {
        fetchPolicy: 'no-cache',
        variables: {
          tableName: props?.catalog?.contentSet?.parameters?.table
        }
      };
    }
  })
)(CatalogDetails);

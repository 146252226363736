import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withGlobalContext } from 'src/GlobalContextProvider';

import { IconButton, Menu, MenuItem, Box } from '@mui/material';
import { withAppSettings } from 'src/AppSettings';

import AccountCircle from '@mui/icons-material/AccountCircle';

import { withFeatures } from 'src/components/Feature';
import Link from 'src/components/Link';
import { isSuperAdmin } from 'src/Auth/common';
import HelpTooltip from 'src/components/HelpTooltip';

import { getAppBarNavigationItems } from './navigationUtil';

const PREFIX = 'AccountMenu';

const classes = {
  appBarOpen: `${PREFIX}-appBarOpen`,
  appBarClosed: `${PREFIX}-appBarClosed`,
  title: `${PREFIX}-title`,
  menuUserDetails: `${PREFIX}-menuUserDetails`,
  menuCurrentUser: `${PREFIX}-menuCurrentUser`,
  menuExtraAction: `${PREFIX}-menuExtraAction`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.appBarOpen}`]: {
    marginLeft: `${theme.evSizes.navigationWidth}px`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: `calc(100% - ${theme.evSizes.navigationWidth}px)`
  },

  [`& .${classes.appBarClosed}`]: {
    marginLeft: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: '100%'
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
    marginLeft: theme.spacing(1)
  },

  [`& .${classes.menuUserDetails}`]: {
    marginLeft: theme.spacing(1)
  },

  [`& .${classes.menuCurrentUser}`]: {
    margin: theme.spacing(1)
  },

  [`& .${classes.menuExtraAction}`]: {
    position: 'absolute',
    right: theme.spacing(1)
  }
}));

const AccountMenu = ({
  features,
  loading,
  location: { pathname },
  appSettings,
  globalContext
}) => {
  const userName = globalContext?.me?.name;
  const userEmail = globalContext?.me?.email;

  const [accountAnchorElement, setAccountAnchorElement] = useState(null);
  const accountMenuOpen = Boolean(accountAnchorElement);

  // We don't want to hide the account menu for EV admins,
  // even if it has been disabled in skin settings for regular users
  if (!features.accountMenu && !isSuperAdmin) {
    return null;
  }

  return (
    <Root>
      <IconButton
        aria-haspopup="true"
        aria-owns={accountMenuOpen ? 'menu-appbar' : undefined}
        data-amp-click-account-menu={JSON.stringify({ action: 'menu' })}
        color="inherit"
        disabled={loading}
        onClick={event => setAccountAnchorElement(event.currentTarget)}
        size="large"
      >
        <AccountCircle />
      </IconButton>
      {!loading && (
        <Menu
          anchorEl={accountAnchorElement}
          id="menu-appbar"
          onClose={() => setAccountAnchorElement(null)}
          open={accountMenuOpen}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            style: {
              minWidth: '200px'
            }
          }}
        >
          {getAppBarNavigationItems(appSettings, features).map(item => (
            <Box
              display="flex"
              width={1}
              alignItems="center"
              justifyContent="space-between"
              key={item?.path}
            >
              <MenuItem
                key={`appBarMenuItem-${item.path}`}
                component={Link}
                data-amp-click-account-menu={JSON.stringify({
                  path: item.path,
                  action: 'link'
                })}
                onClick={() => setAccountAnchorElement(null)}
                selected={pathname === item.path}
                to={item.path}
                sx={{ flexGrow: 1 }}
              >
                {item.name}
              </MenuItem>
              {item.extraAction && (
                <div className={classes.menuExtraAction}>
                  {item.extraAction}
                </div>
              )}
            </Box>
          ))}
          <MenuItem
            className={classes.menuCurrentUser}
            disabled
            key="currentUserAppBar"
          >
            <AccountCircle />{' '}
            <span className={classes.menuUserDetails}>
              {userName}
              <br />
              {userEmail}
            </span>
          </MenuItem>
        </Menu>
      )}
      <Box
        sx={{ fontSize: 18, position: 'relative', top: '-10px', right: '18px' }}
      >
        {!features.accountMenu && isSuperAdmin && (
          <HelpTooltip title="The account menu is disabled in the org's skin settings. It is only visible to Evocalize admin users." />
        )}
      </Box>
    </Root>
  );
};

export default flow(
  withAppSettings,
  withGlobalContext,
  withRouter,
  withFeatures
)(AccountMenu);

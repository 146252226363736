import { useState, useMemo, useEffect } from 'react';
import { filter, omit } from 'lodash';
import { useMutation } from '@apollo/client';
import { t } from 'i18next';
import { useFeatures } from 'src/components/Feature';
import { dayjs } from 'src/common/dates';

import { Tabs, Tab, Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { isSuperAdmin } from 'src/Auth/common';
import Loading from 'src/components/Loading';
import { usePagination } from 'src/components/Pagination/hooks';
import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';

import { GalleryFilterSideBar } from 'src/pages/Gallery/GalleryFilterSidebar';
import { getMediaAsset } from './queries';
import { updateAssetv2 } from './mutations';
import { propsToGalleryFilter } from './utilities';
import { useLoadGalleriesV2 } from './hooks';
import GalleryAddAsset from './GalleryAddAsset';
import GalleryContent from './GalleryContent';
import GalleryUploadHandler from './GalleryUploadHandler';
import GalleryThirdParty from './GalleryThirdParty';

import {
  MEDIA_TYPE_FILTER_VALUES,
  MEDIA_SOURCES,
  RESULTS_PER_PAGE,
  ASSET_STATUS,
  getFilterableMediaSources,
  MEDIA_SOURCE_THIRD_PARTY,
  GALLERY_TYPE
} from './constants';

const getText = () => ({
  error: t('gallery:overview.error'),
  addMediaTab: t('gallery:overview.uploadTab'),
  mediaLibraryTab: t('gallery:overview.libraryTab'),
  searchTab: t('gallery:overview.searchTab'),
  filterMenuType: {
    title: t('gallery:filterMenuType.title'),
    [MEDIA_TYPE_FILTER_VALUES.all]: t('gallery:filterMenuType.all'),
    [MEDIA_TYPE_FILTER_VALUES.image]: t('gallery:filterMenuType.images'),
    [MEDIA_TYPE_FILTER_VALUES.video]: t('gallery:filterMenuType.video')
  },
  filterMenuSource: {
    title: t('gallery:filterMenuSource.title'),
    [MEDIA_SOURCES.User]: t('gallery:filterMenuSource.user'),
    [MEDIA_SOURCES.Corporate]: t('gallery:filterMenuSource.corporate'),
    [MEDIA_SOURCE_THIRD_PARTY]: t('gallery:filterMenuSource.thirdParty')
  },
  uploadError: t('gallery:overview.uploadingError'),
  filterHeader: t('gallery:overview.filterHeader'),
  categoryHeader: t('gallery:overview.categoryHeader')
});

/**
 * Wrapper (or empty!) element to align the left gutter of the gallery.
 * This should be placed on any gallery "rows" that want to be aligned
 * with the gallery top bar. This should be used in conjunction with
 * the GalleryMainSection.
 *
 * If you don't want content here, just don't pass children.
 */
const GalleryLeftGutter = ({ children }) => {
  // For both the gallery left gutter and main section we'll align them
  // via a 2 | 10 grid on md+ screens.
  // For anything smaller than md we'll give everything its own line of 12
  return (
    <Grid item md={2} xs={12}>
      {children}
    </Grid>
  );
};

/**
 * Wrapper (or empty!) element to align the main section of the gallery.
 * This should be used in conjunction with the GalleryLeftGutter.
 *
 * If you don't want content here, just don't pass children.
 */
const GalleryMainSection = ({ children }) => {
  return (
    <Grid item md={10} xs={12}>
      {children}
    </Grid>
  );
};

/**
 * Renders the media gallery.
 *
 * Prop notes:
 * selectedMediaSources - This is an array of strings that if empty corresponds
 *                        "all sources selected"
 * defaultMediaSources - This is an object which contains the "allowed" media sources
 */
const Gallery = props => {
  const {
    showFilter = false,
    allowSelect = false,
    mediaType,
    setMediaType,
    defaultMediaSources,
    selectedMediaSources,
    setSelectedMediaSources,
    customUploadTabText,
    selectedGalleryItems,
    setSelectedGalleryItem,
    sizeConstraint,
    restrictGalleryTypeTo,
    isModal = false,
    isMultiSelect = false,
    internalOnly = false,
    internalOnlyAllowSvg = true, // default to true to account for legacy behavior
    galleryType = GALLERY_TYPE.media, // default to media gallery because when its not passed this means its the gallery page vs an input
    contentItems = []
  } = props;

  const { giphySearch, shutterstockSearch } = useFeatures();

  const { loading, error, refetch, data } = useLoadGalleriesV2({
    mediaType,
    defaultMediaSources,
    selectedMediaSources,
    internalOnly
  });
  const gallery = data?.gallery;
  const text = useMemo(() => getText(), []);
  const getMediaAssetQuery = useInvokableQuery(getMediaAsset);
  const [updateAssetMutation] = useMutation(updateAssetv2);

  // we are on a internal gallery and the user is not an admin?
  const disableAddMedia = internalOnly && !isSuperAdmin();

  // we want to make sure the gallery has loaded at least once completely
  // before we show the UI and uploading options (to avoid uploading race conditions)
  const [firstLoaded, setFirstLoaded] = useState(false);

  if (!firstLoaded && loading === false) {
    setFirstLoaded(true);
  }

  // tabs handler
  // some constants for switching tabs
  const tabs = {
    gallery: 'gallery',
    upload: 'upload',
    search: 'search'
  };

  const [page, setPage] = useState(tabs.gallery);
  const handleTabs = (event, newValue) => {
    setPage(newValue);
  };

  const [uploadStatuses, setUploadStatuses] = useState({});

  const { navigateNext, navigatePrev, resetPagination, previousCursors } =
    usePagination({
      edges: gallery?.assetsv3?.edges,
      resultsPerPage: RESULTS_PER_PAGE,
      refetchCallback: refetch,
      refetchOptions: { filter: propsToGalleryFilter(props) }
    });

  const isFirstPage = previousCursors.length === 0;

  const galleryRefetch = () => {
    // when we refetch the gallery (on upload or delete)
    // we want to start over at page one
    resetPagination();
    refetch({
      filter: propsToGalleryFilter(props),
      first: RESULTS_PER_PAGE,
      after: null
    });
  };

  const allAssets = (gallery?.assetsv3?.edges || []).map(({ node }) => {
    return node;
  });

  const assets = [
    // add images from the content
    ...(isFirstPage
      ? contentItems.map(item => ({
          id: item.value,
          isDeleted: false,
          link: item.link,
          name: item.value,
          source: 'User',
          status: 'complete',
          thirdPartyId: null,
          type: 'image'
        }))
      : []),
    // and the rest of the assets
    ...filter(allAssets, { status: ASSET_STATUS.complete })
  ];

  const setAssetStatusError = async assetId => {
    try {
      await updateAssetMutation({
        variables: {
          input: {
            assetId,
            status: ASSET_STATUS.error
          }
        }
      });
    } catch (e) {
      // error
    }
  };

  // this section we need to handle 3rd party uploads (shutterstock etc)
  // where the backend is uploading the image so we have to poll for complete
  // const previousLoaded = usePrevious(loading);
  const pollUploadStatus = (assetId, waitTime = 500, numberOfRetries = 0) => {
    // give up after 4 minutes
    if (numberOfRetries > 60) {
      return;
    }
    setTimeout(async () => {
      const { data } = await getMediaAssetQuery({
        assetId
      });

      if (data?.mediaAsset?.status === ASSET_STATUS.pending) {
        if (
          data?.mediaAsset?.source !== MEDIA_SOURCES.Shutterstock &&
          data?.mediaAsset?.source !== MEDIA_SOURCES.Giphy
        ) {
          // if we have a pending user upload here that means it was interrupted on upload with
          // a refresh or navigating away from that page
          setAssetStatusError(assetId);
          setUploadStatuses(currentStatuses => {
            return omit(currentStatuses, [assetId]);
          });
          return;
        }

        pollUploadStatus(
          assetId,
          // increase the polling time with a max of 4 seconds
          waitTime < 4000 ? waitTime + 500 : waitTime,
          numberOfRetries + 1
        );
      } else {
        setUploadStatuses(currentStatuses => omit(currentStatuses, assetId));

        // if we are in a select modal, select the finished item.
        if (allowSelect && data?.mediaAsset?.link) {
          setSelectedGalleryItem(data?.mediaAsset);
        }
        galleryRefetch();
      }
    }, waitTime);
  };

  // check for pending uploads
  useEffect(() => {
    if (!loading) {
      allAssets.forEach(asset => {
        if (
          asset.status === ASSET_STATUS.pending &&
          !uploadStatuses[asset.id] // we aren't already tracking it
        ) {
          if (
            asset.source === MEDIA_SOURCES.User ||
            asset.source === MEDIA_SOURCES.Corporate ||
            asset.source === MEDIA_SOURCES.Internal
          ) {
            // if we have a pending upload here that means it was interrupted on upload with
            // a refresh or navigating away from that page
            // for sanity if it's been more than a day, we can assume it's not going to finish
            if (dayjs().isAfter(dayjs(asset.createdAt).add(1, 'day'))) {
              setAssetStatusError(asset.id, true);
            }
          } else {
            setUploadStatuses(currentStatuses => {
              return {
                ...currentStatuses,
                [asset.id]: {
                  assetID: asset.id,
                  name: asset.name,
                  status: ASSET_STATUS.pending
                }
              };
            });
            pollUploadStatus(asset.id);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, gallery]);

  const returnToGallery = () => {
    handleTabs(null, tabs.gallery);
  };

  if (error) {
    return <Loading error={error} errorMessage={text.error} />;
  }

  const uploadHandlerSharedProps = {
    setSelectedGalleryItem,
    setUploadStatuses,
    internalOnly,
    internalOnlyAllowSvg,
    sizeConstraint,
    restrictGalleryTypeTo,
    galleryRefetch,
    returnToGallery,
    galleryType,
    mediaType
  };

  const showThirdPartyUpload = () => {
    if (!(giphySearch || shutterstockSearch)) {
      return false;
    }
    if (
      mediaType === MEDIA_TYPE_FILTER_VALUES.image ||
      mediaType === MEDIA_TYPE_FILTER_VALUES.all
    ) {
      return giphySearch;
    }
    if (
      mediaType === MEDIA_TYPE_FILTER_VALUES.video ||
      mediaType === MEDIA_TYPE_FILTER_VALUES.all
    ) {
      return shutterstockSearch;
    }
    return true;
  };

  // Deal with our "Third Party" grouping by converting to our media sources
  // that can actually be filtered upon.
  const mediaSourceFilters = getFilterableMediaSources(defaultMediaSources);

  return (
    <Grid container>
      <GalleryLeftGutter />
      <Grid item sm={10} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={page}
          onChange={handleTabs}
          aria-label="media library menu"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab
            sx={{ textTransform: 'none' }}
            label={text.mediaLibraryTab}
            value={tabs.gallery}
            data-cy="media-library-tab"
            data-amp-click-media-library-tabs-gallery-click
          />
          {!disableAddMedia && (
            <Tab
              sx={{ textTransform: 'none' }}
              icon={<AddCircleIcon />}
              iconPosition="start"
              label={customUploadTabText || text.addMediaTab}
              value={tabs.upload}
              data-cy="media-upload-tab"
              data-amp-click-media-library-tabs-upload-click
            />
          )}
          {!disableAddMedia && showThirdPartyUpload() && (
            <Tab
              sx={{ textTransform: 'none' }}
              label={text.searchTab}
              value={tabs.search}
              data-cy="media-search-tab"
              data-amp-click-media-library-tabs-search-click
            />
          )}
        </Tabs>
      </Grid>
      {!firstLoaded && <Loading />}
      {page === tabs.gallery && firstLoaded && (
        <>
          <GalleryLeftGutter>
            <GalleryFilterSideBar
              text={text}
              mediaSources={mediaSourceFilters}
              selectedMediaSources={selectedMediaSources}
              setSelectedMediaSources={setSelectedMediaSources}
              showFilter={showFilter}
              mediaType={mediaType}
              setMediaType={setMediaType}
            />
          </GalleryLeftGutter>
          <GalleryMainSection>
            <GalleryUploadHandler
              {...uploadHandlerSharedProps}
              noClick={assets.length > 0}
            >
              <GalleryContent
                allowSelect={allowSelect}
                assets={assets}
                noAssets={assets.length === 0}
                selectedGalleryItems={selectedGalleryItems}
                setSelectedGalleryItem={setSelectedGalleryItem}
                galleryRefetch={galleryRefetch}
                uploadStatuses={uploadStatuses}
                pageInfo={gallery?.assetsv3?.pageInfo}
                navigateNext={navigateNext}
                navigatePrev={navigatePrev}
                galleryLoading={loading}
                selectedMediaSources={selectedMediaSources}
                galleryType={galleryType}
                text={text}
                isMultiSelect={isMultiSelect}
              />
            </GalleryUploadHandler>
          </GalleryMainSection>
        </>
      )}
      {page === tabs.upload && firstLoaded && (
        <>
          <GalleryLeftGutter />
          <GalleryMainSection>
            <GalleryUploadHandler {...uploadHandlerSharedProps}>
              <GalleryAddAsset />
            </GalleryUploadHandler>
          </GalleryMainSection>
        </>
      )}
      {page === tabs.search && firstLoaded && (
        <>
          <GalleryLeftGutter />
          <GalleryMainSection>
            <GalleryUploadHandler {...uploadHandlerSharedProps}>
              <GalleryThirdParty
                returnToGallery={returnToGallery}
                mediaType={mediaType}
                galleryRefetch={galleryRefetch}
                isModal={isModal}
              />
            </GalleryUploadHandler>
          </GalleryMainSection>
        </>
      )}
    </Grid>
  );
};

export default Gallery;

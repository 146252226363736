import { Skeleton } from '@mui/material';
import {
  Root as FacebookAdPreviewRoot,
  classes as facebookAdPreviewClasses
} from './FacebookAdPreview';
import {
  Root as FacebookAdPreviewCardRoot,
  classes as facebookAdPreviewCardClasses
} from './FacebookAdPreviewCard';

const classes = {
  ...facebookAdPreviewClasses,
  ...facebookAdPreviewCardClasses
};

const FacebookAdPreviewSkeleton = () => {
  return (
    <>
      <FacebookAdPreviewRoot className={classes.preview}>
        <div className={classes.previewHeader} style={{ gridArea: '1 / 1' }}>
          <Skeleton width={50} />
        </div>
        <div className={classes.companyRow} style={{ gridArea: '2 / 1' }}>
          <div
            className={classes.companyNameContainer}
            style={{ gridArea: '1 / 1' }}
          >
            <div style={{ display: 'inline-block' }}>
              <Skeleton variant="rectangular" width={25} height={25} />
            </div>
            <div
              className={classes.companyNameInline}
              style={{ marginLeft: '10px' }}
            >
              <div className={classes.companyName}>
                <Skeleton width={50} />
              </div>
              <Skeleton width={50} />
            </div>
          </div>
        </div>
        <div
          className={classes.bodyText}
          style={{ gridArea: '3 / 1', marginTop: '10px' }}
        >
          <Skeleton width={200} />
        </div>
        <div className={classes.previewCardsWrap}>
          <div className={classes.previewCards}>
            <div style={{ width: '100%' }}>
              <Skeleton variant="rectangular" width={361} height={271} />
              <FacebookAdPreviewCardRoot className={classes.previewCardFooter}>
                <div className={classes.previewCardLeadAdText}>
                  <Skeleton />
                </div>
              </FacebookAdPreviewCardRoot>
            </div>
          </div>
        </div>
      </FacebookAdPreviewRoot>
    </>
  );
};

export default FacebookAdPreviewSkeleton;

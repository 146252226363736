import { has } from 'lodash';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';

import { Button } from '@mui/material';

import { GALLERY_TYPE, MEDIA_SOURCES } from '../constants';
import MediaAsset from './MediaAsset';

const PREFIX = 'TileWrapper';

const classes = {
  container: `${PREFIX}-container`,
  lightboxContainer: `${PREFIX}-lightboxContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.container}`]: {
    position: 'relative',
    width: '100%',
    height: '100%',

    '& img': {
      objectFit: 'contain'
    }
  },

  [`& .${classes.lightboxContainer}`]: {
    width: '90%',
    height: '90%',
    maxWidth: '700px',
    maxHeight: '700px'
  }
}));

const TileWrapper = props => {
  const {
    allowSelect,
    onClick,
    isLightbox,
    galleryType,
    asset,
    type,
    showThumbnail
  } = props;
  const isGiphy = MEDIA_SOURCES.Giphy === asset?.source;
  const isMediaGallery = galleryType === GALLERY_TYPE.media;
  const shouldFetchAsset =
    isMediaGallery && has(asset, 'id') && !has(asset, 'link');

  const assetComponent = (
    <MediaAsset
      asset={asset}
      isGiphy={isGiphy}
      isLightbox={isLightbox}
      shouldFetchAsset={shouldFetchAsset}
      allowSelect={allowSelect}
      showThumbnail={showThumbnail}
      type={type}
    />
  );

  return (
    <Root>
      {allowSelect ? (
        <Button className={classes.container} onClick={onClick}>
          {assetComponent}
        </Button>
      ) : (
        <div
          className={classnames(classes.container, {
            [classes.lightboxContainer]: isLightbox
          })}
        >
          {assetComponent}
        </div>
      )}
    </Root>
  );
};

export default TileWrapper;

import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import { flow, noop } from 'lodash';
import { generatePath, matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';

import { Divider, Drawer } from '@mui/material';

import { withAppSettings } from 'src/AppSettings';
import { useAppState } from 'src/AppStateProvider';

import { paths } from 'src/routes/paths';

import { withFeatures } from 'src/components/Feature';

import NavigationList from './NavigationList';
import OfficeSelector from './OfficeSelector';
import AdminNavigationList from './AdminNavigationList';

const PREFIX = 'ChromeDrawer';

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  companyLogoContainer: `${PREFIX}-companyLogoContainer`,
  companyLogo: `${PREFIX}-companyLogo`,
  linkBackContainer: `${PREFIX}-linkBackContainer`,
  linkBackChild: `${PREFIX}-linkBackChild`,
  linkBackIcon: `${PREFIX}-linkBackIcon`
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: `${theme.evSizes.navigationWidth}px`
  },

  [`&.${classes.drawerOpen}`]: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: `${theme.evSizes.navigationWidth}px`
  },

  [`&.${classes.drawerClose}`]: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 0
  },

  [`& .${classes.companyLogoContainer}`]: {
    width: `${theme.evSizes.navigationWidth}px`,
    padding: theme.spacing(2)
  },

  [`& .${classes.companyLogo}`]: {
    width: '100%',
    cursor: 'pointer'
  },

  [`& .${classes.linkBackContainer}`]: {
    padding: 0,
    width: `${theme.evSizes.navigationWidth}px`
  },

  [`& .${classes.linkBackChild}`]: {
    paddingLeft: theme.spacing(4)
  },

  [`& .${classes.linkBackIcon}`]: {
    margin: 0,
    width: '24px',
    height: '24px'
  }
}));

// This function is designed to determine if the logo link url is overridden
// to go to an external site. If so, we use window.location. Otherwise we send
// the user to the dashboard.
const handleLogoClick = (history, linkBackTo) => {
  const linkBackUrl = linkBackTo;

  if (linkBackUrl) {
    window.location = linkBackUrl;
    return;
  }
  // Default case if no settings exist in the skin to override it.
  history.push(generatePath(paths.dashboard.base));
};

const ChromeDrawer = props => {
  const { loading, features, history, location, appSettings } = props;
  const { sideNavOpen } = useAppState();

  const isAdminPath = !!matchPath(location.pathname, {
    path: paths.admin.base
  });
  const linkBackTo = appSettings?.app?.general?.linkBack?.url;

  return (
    <StyledDrawer
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: sideNavOpen,
        [classes.drawerClose]: !sideNavOpen
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: sideNavOpen,
          [classes.drawerClose]: !sideNavOpen
        })
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      open={sideNavOpen}
      variant="permanent"
    >
      <div className={classes.companyLogoContainer}>
        <img
          alt={`${appSettings?.app.general.baseTitle} Logo`}
          className={classes.companyLogo}
          src={appSettings?.evAssets.logoUrl}
          onClick={() =>
            features?.logoClick ? handleLogoClick(history, linkBackTo) : noop
          }
        />
      </div>
      <Divider />
      <OfficeSelector />
      <Divider />
      {!loading && (
        <>{isAdminPath ? <AdminNavigationList /> : <NavigationList />}</>
      )}
    </StyledDrawer>
  );
};

export default flow(withAppSettings, withRouter, withFeatures)(ChromeDrawer);

import { styled } from '@mui/material/styles';

const PREFIX = 'PreviewCardButton';

const classes = {
  container: `${PREFIX}-container`,
  callToAction: `${PREFIX}-callToAction`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    gridArea: '2 / 2',
    textAlign: 'right'
  },

  [`& .${classes.callToAction}`]: {
    padding: '4px',
    backgroundColor: '#f4f5f7',
    border: '1px solid #c9cbcf',
    color: '#45494e',
    fontSize: '11px',
    lineHeight: '12px',
    fontWeight: 'bold',
    display: 'inline-block',
    fontFamily: "'Helvetica Neue', sans-serif"
  }
}));

// No translations for this second right now - I'm concerned doing this many
// every time this element gets re-rendered is going to be too expensive. We
// should find a cleaner solution to get this defined once at app-start or
// similar once we've really had time to clean up ad previews.
const CALLS_TO_ACTION_STRINGS = {
  NO_BUTTON: 'No Button',
  APPLY_NOW: 'Apply Now',
  BOOK_TRAVEL: 'Book Now',
  BUY_NOW: 'Buy Now',
  INSTALL_APP: 'Install App',
  DOWNLOAD: 'Download',
  TRAVEL: 'Book Now',
  CALL_NOW: 'Call Now',
  CONTACT_US: 'Contact Us',
  DONATE_NOW: 'Donate Now',
  GET_DIRECTIONS: 'Get Directions',
  GET_OFFER: 'Get Offer',
  GET_QUOTE: 'Get Quote',
  INSTALL_MOBILE_APP: 'Install Mobile App',
  LEARN_MORE: 'Learn More',
  LIKE_PAGE: 'Like Page',
  LISTEN_MUSIC: 'Listen To Music',
  MESSAGE_PAGE: 'Message Page',
  OPEN_LINK: 'Open Link',
  OPEN_MOVIES: 'Open Movies',
  PLAY_GAME: 'Play Game',
  RECORD_NOW: 'Record Now',
  SELL_NOW: 'Sell Now',
  SHOP_NOW: 'Shop Now',
  SIGN_UP: 'Sign Up',
  SUBSCRIBE: 'Subscribe',
  USE_APP: 'Use App',
  USE_MOBILE_APP: 'Use Mobile App',
  VOTE_NOW: 'Vote Now',
  WATCH_MORE: 'Watch More',
  WATCH_VIDEO: 'Watch Video'
};

const PreviewCardButton = ({ callToAction }) => {
  if (!callToAction || !CALLS_TO_ACTION_STRINGS[callToAction]) {
    return <span />;
  }

  return (
    <Root className={classes.container}>
      <div className={classes.callToAction}>
        {CALLS_TO_ACTION_STRINGS[callToAction]}
      </div>
    </Root>
  );
};

export default PreviewCardButton;

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

import AccountCircle from '@mui/icons-material/AccountCircle';

const PREFIX = 'UserListItem';

const classes = {
  listItem: `${PREFIX}-listItem`
};

const StyledListItem = styled(ListItem)(() => ({
  [`&.${classes.listItem}`]: {
    padding: '4px 16px'
  }
}));

const UserListItem = props => {
  const { handleClick, user, style, selected } = props;

  return (
    <StyledListItem
      button
      className={classes.listItem}
      style={style}
      selected={selected}
      onClick={() => handleClick(user)}
    >
      <ListItemIcon>
        <AccountCircle />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="body2" display="block">
            {user.name}
          </Typography>
        }
        secondary={
          <Typography variant="body2" color="textSecondary" display="block">
            {user.email}
          </Typography>
        }
      />
    </StyledListItem>
  );
};

export default UserListItem;

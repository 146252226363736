import CardMedia from './DisplayCardMedia';
import { Root as DisplaySquareAdRoot, classes } from './DisplaySquareAd';

const DisplayRectangleAd = props => {
  const { rectangleImageUrl, headline, description, businessName } = props;
  return (
    <DisplaySquareAdRoot className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.cardContainer}>
          <div className={classes.fakeTextContainer}>
            <div className={classes.placeholderText} />
            <div className={classes.placeholderText} />
            <div className={classes.placeholderText} />
          </div>
          <div className={classes.profileContainer}>
            <div className={classes.fakeImage} />
            <div className={classes.placeholderTextShort} />
          </div>
        </div>

        <CardMedia
          headline={headline}
          description={description}
          businessName={businessName}
          image={rectangleImageUrl}
        />
      </div>
    </DisplaySquareAdRoot>
  );
};

export default DisplayRectangleAd;

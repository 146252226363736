import { Skeleton, Box } from '@mui/material';

import { Root as TiktokAdPreviewRoot, classes } from './TiktokAdPreview';

const TiktokAdPreviewSkeleton = () => {
  return (
    <TiktokAdPreviewRoot className={`${classes.container} notranslate`}>
      <div className={classes.previewContent}>
        <div className={classes.infoOverlay}>
          <div className={classes.topBanner}>
            <Skeleton width={100} />
          </div>
          <div className={classes.rightBar}>
            <div className={classes.avatar}>
              <Skeleton variant="circular" width={40} height={40} />
            </div>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2.5}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
          </div>
          <div className={classes.infoContainer}>
            <Skeleton width={160} />
            <div className={classes.infoLabelContainer}>
              <Skeleton width={110} />
            </div>
          </div>
        </div>
      </div>
    </TiktokAdPreviewRoot>
  );
};

export default TiktokAdPreviewSkeleton;

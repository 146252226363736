import { InputAdornment, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';

export interface InputTooltipProps {
  tooltipText: string;
}

/**
 * Input tooltip suited for placement at the end of a form input.
 */
export const InputTooltip = ({ tooltipText }: InputTooltipProps) => {
  return (
    <InputAdornment
      sx={{
        color: 'grey.500',
        position: 'absolute',
        top: '13px',
        right: '3px'
      }}
      position="end"
    >
      <Tooltip arrow title={tooltipText}>
        <HelpIcon
          sx={{
            width: '20px'
          }}
        />
      </Tooltip>
    </InputAdornment>
  );
};

import { useState, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { chain, flow, isEmpty, omit } from 'lodash';
import classNames from 'classnames';
import { t } from 'i18next';
import { reduxForm, getFormValues } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';

import {
  Button,
  Drawer,
  IconButton,
  Typography,
  ListItemButton,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { DynamicForm } from 'src/components/ReduxForm';
import {
  getInitialValuesFromInputsConfig,
  configureInputs
} from 'src/components/ReduxForm/helpers';

import { publishersInputs, publisherInputSupplements } from './inputConfigs';
import { getPrototypeHelperData } from '../../queries';

const PREFIX = 'AddPrototypeDrawer';

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  container: `${PREFIX}-container`,
  headerContainer: `${PREFIX}-headerContainer`,
  body: `${PREFIX}-body`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    flexShrink: 0
  },

  [`& .${classes.drawerOpen}`]: {
    width: '600px'
  },

  [`& .${classes.drawerClose}`]: {
    width: 0
  },

  [`& .${classes.container}`]: {
    position: 'relative',
    width: '100%'
  },

  [`& .${classes.headerContainer}`]: {
    alignItems: 'center',
    background: 'white',
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    position: 'fixed',
    zIndex: '5'
  },

  [`& .${classes.body}`]: {
    position: 'relative',
    margin: `${theme.spacing(11)} 0 ${theme.spacing(4)} 0`,
    padding: `0 ${theme.spacing(2)}`
  }
}));

const pageText = () => ({
  header: t('admin:blueprintBuilder.stepPublishersAddPrototypeHeader'),
  addButton: t('admin:blueprintBuilder.stepPublishersAddButton'),
  addButtonSecondary: t(
    'admin:blueprintBuilder.stepPublishers.addButtonSecondary'
  )
});

const buttonTypes = {
  button: 'button',
  listItemButton: 'listItemButton'
};

const AddPrototypeDrawer = props => {
  const {
    handleSubmit,
    addNewPublisher,
    formValues,
    allUnitData,
    reset,
    publisherCount,
    buttonType = buttonTypes.button
  } = props;
  const text = useMemo(() => pageText(), []);

  const [drawerOpen, setDrawerState] = useState(false);
  const handleCloseDrawer = () => {
    reset();
    setDrawerState(false);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  let possiblePrototypes = null;

  // Configure Prototype Code Enums and sort them alphabetically by name
  const codeEnums = chain(allUnitData)
    .reduce((accum, current) => {
      if (current?.channelCode === formValues?.channelCode) {
        accum.push({
          name: current?.name,
          value: current?.code
        });

        if (current?.code === formValues?.code) {
          possiblePrototypes = current.prototypes;
        }
      }
      return accum;
    }, [])
    .sortBy('name')
    .value();

  const prototypeEnums = possiblePrototypes
    ? chain(possiblePrototypes)
        .map(prototype => {
          return {
            name: prototype?.name,
            value: prototype.slug
          };
        })
        .sortBy('name')
        .value()
    : [];

  const updatedPublishersInputs = useMemo(() => {
    return configureInputs({
      inputs: [
        ...publisherInputSupplements({
          hasCodeEnums: !isEmpty(codeEnums)
        }),
        ...publishersInputs({ isCreate: true })
      ],
      enumInputs: {
        prototype: 'prototype',
        code: 'code'
      },
      enumerationValues: [
        {
          enumeration: 'prototype',
          values: prototypeEnums
        },
        {
          enumeration: 'code',
          values: codeEnums
        }
      ]
    });
  }, [publishersInputs, codeEnums]);

  const handleAddNewPublisher = useCallback(data => {
    // remove input data for supplement inputs before adding publisher
    const newPublisher = omit(
      data,
      publisherInputSupplements({}).map(input => input.name)
    );

    newPublisher.slug = uuidv1();
    newPublisher.facets = [];

    addNewPublisher(newPublisher, publisherCount);

    reset();
    handleCloseDrawer();
  }, []);

  return (
    <Root>
      {buttonType === buttonTypes.button && (
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenDrawer}
        >
          {text.header}
        </Button>
      )}
      {buttonType === buttonTypes.listItemButton && (
        <ListItemButton
          key="addButton"
          dense
          divider
          onClick={handleOpenDrawer}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>

          <ListItemText
            primary={<strong>{text.addButton}</strong>}
            secondary={text.addButtonSecondary}
          />
        </ListItemButton>
      )}
      <Drawer
        anchor="right"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen
          })
        }}
        ModalProps={{
          hideBackdrop: true,
          disableScrollLock: true
        }}
        open={drawerOpen}
        variant="temporary"
      >
        <div className={classes.container}>
          <div
            className={classNames(classes.headerContainer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen
            })}
          >
            <Typography variant="h6" align="center">
              {text.header}
            </Typography>
            <IconButton onClick={handleCloseDrawer} size="large">
              <CloseIcon />
            </IconButton>
          </div>
          <form>
            <div className={classes.body}>
              <DynamicForm inputs={updatedPublishersInputs} />
              <br />
              <Button
                color="primary"
                onClick={handleSubmit(handleAddNewPublisher)}
                startIcon={<AddIcon />}
                variant="contained"
              >
                {text.addButton}
              </Button>
            </div>
          </form>
        </div>
      </Drawer>
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues(ownProps?.form)(state);

  const initialValues = {
    ...getInitialValuesFromInputsConfig(publishersInputs({ isCreate: true })),
    ...getInitialValuesFromInputsConfig(publisherInputSupplements({}))
  };

  return {
    formValues,
    initialValues
  };
};

export default flow(
  reduxForm({
    // form: ADD_PROTOTYPE_FORM_NAME, // will come in props so we can have multiple of this on a page
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps),
  graphql(getPrototypeHelperData, {
    name: 'getPrototypeHelperData',
    props: ({ getPrototypeHelperData }) => {
      const allUnitData = getPrototypeHelperData?.getUnits || [];
      return {
        allUnitData
      };
    }
  })
)(AddPrototypeDrawer);

import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { Backdrop, Button, Typography } from '@mui/material';
import { t } from 'i18next';
import Instrumentation from 'src/instrumentation';
import Loading from 'src/components/Loading';
import { withFeatures } from 'src/components/Feature/Feature';

const PREFIX = 'GalleryAddAsset';

const classes = {
  dropzoneContainer: `${PREFIX}-dropzoneContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.dropzoneContainer}`]: {
    width: '100%',
    minHeight: '400px',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    fontSize: '25px',
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    cursor: 'pointer',
    borderRadius: '10px',
    border: `1px dashed ${theme.palette.grey[400]}`,
    boxShadow: `0px 0px 0px 1px ${theme.palette.grey[300]}`,
    '& > *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const pageText = () => ({
  dropHere: t('gallery:addAssets.dropHere'),
  dragAndDrop: t('gallery:addAssets.dragNDrop'),
  or: t('gallery:addAssets.dragNDropOr'),
  uploadFromDevice: t('gallery:addAssets.uploadFromDevice')
});

const AddAsset = ({ isAddingMedia, dropZone }) => {
  const { getRootProps, getInputProps, isDragActive } = dropZone;
  const text = useMemo(() => pageText(), []);

  return (
    <Root>
      <Backdrop
        style={{ zIndex: '9999', position: 'absolute' }}
        open={isAddingMedia}
      >
        <Loading />
      </Backdrop>
      <div
        role="log"
        {...getRootProps({ className: classes.dropzoneContainer })}
        onClick={e => {
          getRootProps().onClick(e);
          Instrumentation.logEvent(Instrumentation.Events.MediaLibraryUpload);
        }}
      >
        <input {...getInputProps()} data-amp-click-media-library-upload />
        {isDragActive ? (
          <div>{text.dropHere}</div>
        ) : (
          <>
            <Typography variant="h6" component="p">
              {text.dragAndDrop}
            </Typography>
            <Typography variant="body1" component="p">
              {text.or}
            </Typography>
            {/*
            This button does not respond to clicks, but implicitly bubbles them up to the parent ele
            which handles upload.
            This way the user can click on this button or on the whole area and have the same behavior!
            */}
            <Button variant="contained">{text.uploadFromDevice}</Button>
          </>
        )}
      </div>
    </Root>
  );
};

export default flow(withFeatures)(AddAsset);

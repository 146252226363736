import { flow, pick, isString, pickBy } from 'lodash';
import { styled } from '@mui/material/styles';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { reduxForm } from 'redux-form';

import { CircularProgress, Button, Divider, Typography } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';
import { useSnackbar } from 'notistack';

import { updateCatalog } from './mutations';
import { getCatalogUpdateInputs, getContentThumbnail } from './constants';

const PREFIX = 'CatalogForm';

const classes = {
  updateButton: `${PREFIX}-updateButton`,
  subHeading: `${PREFIX}-subHeading`
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.updateButton}`]: { marginTop: theme.spacing(2) },

  [`& .${classes.subHeading}`]: {
    margin: `${theme.spacing(4)} 0 0 0`,

    '& p': {
      fontWeight: 'bold'
    }
  }
}));

const CatalogForm = ({
  tableNames,
  contentTableColumns,
  handleSubmit,
  submitting,
  invalid,
  updateCatalog,
  initialValues,
  refetch
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async data => {
    const onlyAPIFields = pick(data, [
      ...getCatalogUpdateInputs().map(f => f.name),
      ...getContentThumbnail().map(f => f.name)
    ]);
    const onlyChanged = pickBy(onlyAPIFields, (val, key) => {
      if (isString(val)) {
        return val !== initialValues?.[key];
      }
      // non strings just always send?
      // TODO:
      // we could also do a deep compare here "messy"
      // those would be arrays and nestted objects
      return true;
    });
    try {
      await updateCatalog({
        variables: {
          catalogId: initialValues.id,
          input: onlyChanged
        }
      });

      enqueueSnackbar(t('admin:catalogContent.catalogEditFormSuccess'), {
        variant: 'success'
      });
      refetch();
    } catch (e) {
      enqueueSnackbar(t('admin:catalogContent.catalogEditFormError'), {
        variant: 'error'
      });
      refetch();
    }
  };

  return (
    <Root autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
      <DynamicForm
        inputs={getCatalogUpdateInputs({
          tableNames,
          contentTableColumns
        })}
      />
      <div className={classes.subHeading}>
        <Typography>
          <Trans i18nKey="admin:catalogContent.contentThumbnailHeading">
            Selected Content Thumbnail
          </Trans>
        </Typography>
        <Divider />
      </div>
      <DynamicForm
        inputs={getContentThumbnail({
          contentTableColumns
        })}
      />
      <Button
        color="primary"
        variant="outlined"
        type="submit"
        disabled={submitting || invalid}
        endIcon={submitting && <CircularProgress size={15} />}
        className={classes.updateButton}
      >
        <Trans i18nKey="admin:catalogContent.updateCatalogButton">
          Update Catalog
        </Trans>
      </Button>
    </Root>
  );
};

export default flow(
  reduxForm({
    // formName from parent
    // initialValues from parent
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(updateCatalog, {
    name: 'updateCatalog'
  })
)(CatalogForm);

import CardMedia from './DisplayCardMedia';

import { Root as DisplaySquareAdRoot, classes } from './DisplaySquareAd';

const DisplayPortraitAd = props => {
  const { portraitImageUrl, headline, description, businessName } = props;

  return (
    <DisplaySquareAdRoot className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.profileContainer}>
          <div className={classes.fakeImage} />
          <div className={classes.placeholderTextShort} />
        </div>
      </div>

      <CardMedia
        headline={headline}
        description={description}
        businessName={businessName}
        image={portraitImageUrl}
      />
    </DisplaySquareAdRoot>
  );
};

export default DisplayPortraitAd;

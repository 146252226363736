import { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { t } from 'i18next';
import Instrumentation from 'src/instrumentation';
import { SEARCH_TABS } from 'src/pages/Gallery/constants';

const PREFIX = 'ThirdPartySearchForm';

const classes = {
  searchHeader: `${PREFIX}-searchHeader`,
  button: `${PREFIX}-button`,
  searchIcon: `${PREFIX}-searchIcon`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.searchHeader}`]: {
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.button}`]: {
    paddingLeft: theme.spacing(1)
  },

  [`& .${classes.searchIcon}`]: {
    cursor: 'pointer'
  }
}));

const getText = ({ selectedSource }) => ({
  searchLabel: {
    [SEARCH_TABS.shutterstock]: t('gallery:thirdParty.searchLabelShutterstock'),
    [SEARCH_TABS.giphy]: t('gallery:thirdParty.searchLabelGiphy')
  }[selectedSource]
});

const ThirdPartySearch = ({ setSearchQuery, selectedSource }) => {
  const [valueState, setValueState] = useState('');
  const text = getText({ selectedSource });

  const setValue = useCallback(e => {
    setValueState(e.target.value);
  }, []);
  const onSearch = useCallback(() => {
    Instrumentation.logEvent(
      `media-library-${selectedSource.toLowerCase()}-search-submit`,
      { searchTerm: valueState }
    );
    setSearchQuery(valueState);
  }, [selectedSource, valueState, setSearchQuery]);

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Root>
      <TextField
        value={valueState}
        onChange={setValue}
        variant="outlined"
        size="small"
        label={text.searchLabel}
        onKeyPress={handleKeypress}
        data-cy="media-search-input"
        InputProps={{
          startAdornment: (
            <SearchIcon onClick={onSearch} className={classes.searchIcon} />
          )
        }}
      />
    </Root>
  );
};

export default flow()(ThirdPartySearch);

import { useState, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { MenuItem, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { withFeatures } from 'src/components/Feature/Feature';
import ThirdPartySearchForm from './ThirdPartySearchForm';
import GalleryGiphy from './GalleryGiphy';
import GalleryShutterstock from './GalleryShutterstock';

import { SEARCH_TABS, MEDIA_TYPE_FILTER_VALUES } from './constants';

const PREFIX = 'GalleryThirdParty';

const classes = {
  searchBar: `${PREFIX}-searchBar`,
  searchHeader: `${PREFIX}-searchHeader`,
  searchSource: `${PREFIX}-searchSource`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.searchBar}`]: {
    margin: theme.spacing(2)
  },

  [`& .${classes.searchHeader}`]: {
    margin: theme.spacing(2)
  },

  [`& .${classes.searchSource}`]: {
    minWidth: '25ch',
    marginRight: theme.spacing(2)
  }
}));

const getText = () => ({
  shutterstockTab: t('gallery:thirdParty.shutterstockTab'),
  giphyTab: t('gallery:thirdParty.giphyTab'),
  searchHeader: t('gallery:thirdParty.searchHeader')
});

const GalleryThirdParty = ({
  returnToGallery,
  galleryRefetch,
  isAddingMedia,
  setIsAddingMedia,
  mediaType,
  features: { giphySearch, shutterstockSearch }
}) => {
  const [searchQuery, setSearchQuery] = useState();

  const [selectedSource, setTab] = useState(() => {
    if (shutterstockSearch) {
      return SEARCH_TABS.shutterstock;
    }
    return SEARCH_TABS.giphy;
  });

  const handleTabs = useCallback(e => {
    setTab(e.target.value);
  }, []);

  const text = useMemo(() => getText(), []);

  const showSelector = () => {
    if (!(shutterstockSearch && giphySearch)) {
      return false;
    }

    if (
      mediaType === MEDIA_TYPE_FILTER_VALUES.image ||
      mediaType === MEDIA_TYPE_FILTER_VALUES.all
    ) {
      return shutterstockSearch;
    }
    if (
      mediaType === MEDIA_TYPE_FILTER_VALUES.video ||
      mediaType === MEDIA_TYPE_FILTER_VALUES.all
    ) {
      return giphySearch;
    }

    return true;
  };

  return (
    <Root>
      <div>
        <Typography className={classes.searchHeader} variant="body1">
          {text.searchHeader}
        </Typography>
        <div className={classes.searchBar}>
          {showSelector() && (
            <TextField
              id="search-source-select"
              value={selectedSource}
              select
              variant="outlined"
              onChange={handleTabs}
              size="small"
              className={classes.searchSource}
            >
              <MenuItem value={SEARCH_TABS.shutterstock}>
                {text.shutterstockTab}
              </MenuItem>
              <MenuItem value={SEARCH_TABS.giphy}>{text.giphyTab}</MenuItem>
            </TextField>
          )}
          <ThirdPartySearchForm
            setSearchQuery={setSearchQuery}
            selectedSource={selectedSource}
          />
        </div>

        {shutterstockSearch && selectedSource === SEARCH_TABS.shutterstock && (
          <>
            <GalleryShutterstock
              searchQuery={searchQuery}
              returnToGallery={returnToGallery}
              galleryRefetch={galleryRefetch}
              setIsAddingMedia={setIsAddingMedia}
              isAddingMedia={isAddingMedia}
            />
          </>
        )}
        {giphySearch && selectedSource === SEARCH_TABS.giphy && (
          <>
            <GalleryGiphy
              searchQuery={searchQuery}
              returnToGallery={returnToGallery}
              galleryRefetch={galleryRefetch}
              setIsAddingMedia={setIsAddingMedia}
              isAddingMedia={isAddingMedia}
            />
          </>
        )}
      </div>
    </Root>
  );
};

export default flow(withFeatures)(GalleryThirdParty);

import { Trans } from 'react-i18next';

import { styled } from '@mui/material/styles';

import { Typography, LinearProgress } from '@mui/material';

const PREFIX = 'UploadingProgress';

const classes = {
  uploadTile: `${PREFIX}-uploadTile`,
  uploadProgress: `${PREFIX}-uploadProgress`
};

const Root = styled('div')(() => ({
  [`&.${classes.uploadTile}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },

  [`& .${classes.uploadProgress}`]: {
    width: '100%'
  }
}));

const UploadingProgress = ({ totalFileCount, completedFileCount }) => {
  return (
    <Root className={classes.uploadTile} data-cy="upload-progress-tile">
      <Typography variant="h6">
        <Trans
          i18nKey="gallery:progress.uploadingFiles"
          values={{ completedFileCount, totalFileCount }}
        />
      </Typography>
      <LinearProgress
        className={classes.uploadProgress}
        color="secondary"
        variant="query"
      />
    </Root>
  );
};

export default UploadingProgress;

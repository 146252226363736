import SuprSendInbox, { useNotifications } from '@suprsend/react-inbox';
import 'react-toastify/dist/ReactToastify.css'; // needed for toast notifications, can be ignored if hideToast=true

import { isNil } from 'lodash';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { darken, IconButton, lighten } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SentryUtil from 'src/common/SentryUtil';
import { useHistory } from 'react-router';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import {
  logNotificationClickEvent,
  SuprSendNotification
} from 'src/components/Notification/notificationEventGenerator';
import Instrumentation from 'src/instrumentation';
import { Events } from 'src/instrumentation/constants';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { useAppSettings } from 'src/AppSettings';

const getText = () => ({
  navigationError: t('notifications:navigationError')
});

interface BellIconReplacementProps {
  onClick: () => void;
}

/**
 * This is a replacement for the SuprSend bell icon.
 * This uses a MUI icon and MUI button to match
 * our other icon buttons in the app.
 */
const BellIconReplacement = ({ onClick }: BellIconReplacementProps) => {
  return (
    <IconButton
      size="large"
      color="inherit"
      sx={{
        height: '48px !important',
        // This is to combat the mr & mt that SuprSend adds to the icon
        mr: '-12px',
        mt: '-12px'
      }}
      data-cy="notification-bell-icon"
      onClick={onClick}
    >
      <NotificationsIcon />
    </IconButton>
  );
};

/**
 * Renders a notification icon button.
 * This opens up to display all the actual notifications.
 */
export const NotificationIconButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const text = getText();
  const history = useHistory();
  const globalContext = useGlobalContext();
  const userId = globalContext?.me?.id;
  const { subscriberId, tenantId, workspaceKey } =
    globalContext?.me?.suprsendSubscriberDetails || {};
  const appSettings = useAppSettings();

  const handleFireAmpEvent = () => {
    Instrumentation.logEvent(Events.NotificationInboxOpen, {
      path: history.location.pathname
    });
  };

  const handleNotificationClick = (notification: SuprSendNotification) => {
    // Regardless of how we handle the notification, log that we did it
    logNotificationClickEvent(notification, history);

    const actionUrl = notification.message.url;

    if (!actionUrl) {
      // If there's no actionURL there's also no click action...
      return;
    }

    // ActionURLs come from the backend in the form of:
    // https://app.evocalize.com/#/architecture/1/program/2
    // (depending on the notification type)
    // This does not work at all in localdev due to a port number on our
    // localdev URL but not in the actionURL.
    // This also breaks due to multi-domain orgs.
    // So instead, we'll try to pull out the relevant part of the URL (the
    // path) and use that to navigate.
    try {
      const url = new URL(actionUrl);
      // Keeping the hash in the URL breaks the routing and changes
      // the path to #/dashboard/#/architecture/1/program/2
      const path = url.hash.replace('#', '');
      history.push(path);
    } catch (e) {
      SentryUtil.addBreadcrumb({
        level: 'error',
        category: 'notification',
        message: 'Failed to handle notification click',
        data: {
          url: actionUrl,
          notificationId: notification.n_id
        }
      });
      if (e instanceof Error) {
        SentryUtil.captureException(e);
      } else {
        const errorMessage = e?.toString() ?? 'Unknown error';
        SentryUtil.captureException(
          new Error(`Failed to handle notification click: ${errorMessage}`)
        );
      }
      enqueueSnackbar(text.navigationError, {
        variant: 'error'
      });
    }
  };

  // Use this as our marker to decide if we finished loading everything suprsend-y
  const suprSend = useNotifications();

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const errorColor = theme.palette.error.main;
  const contrastingFontColor = theme.palette.getContrastText(primaryColor);
  const fontFamily = appSettings?.fonts?.primary?.fontFamily;

  if (
    isNil(suprSend) ||
    isNil(userId) ||
    // Show bell after initial notification load
    suprSend?.initialLoading
  ) {
    return null;
  }

  return (
    <div data-cy="notifications-root">
      <SuprSendInbox
        hideAvatar
        notificationClickHandler={handleNotificationClick}
        distinctId={userId}
        subscriberId={subscriberId || null}
        workspaceKey={workspaceKey || ''}
        tenantId={tenantId}
        bellComponent={() => (
          <BellIconReplacement onClick={handleFireAmpEvent} />
        )}
        theme={{
          // Notification panel header styles
          header: {
            container: { backgroundColor: primaryColor },
            headerText: { color: contrastingFontColor, fontFamily },
            markAllReadText: { color: contrastingFontColor, fontFamily }
          },
          // Unseen notifications badge styles
          badge: {
            color: theme.palette.getContrastText(errorColor),
            backgroundColor: errorColor,
            fontFamily
          },
          // Notification card styles
          notification: {
            actionsMenuItemText: { fontFamily },
            subtext: { fontFamily },
            bodyText: { fontFamily },
            headerText: { fontFamily },
            container: {
              fontFamily,
              unreadBackgroundColor: lighten(primaryColor, 0.9),
              hoverBackgroundColor: lighten(primaryColor, 0.9)
            },
            unseenDot: { backgroundColor: primaryColor },
            actions: [
              {
                container: {
                  hoverBackgroundColor: darken(primaryColor, 0.4),
                  backgroundColor: primaryColor
                },
                text: { color: contrastingFontColor, fontFamily }
              },
              {
                text: { fontFamily }
              }
            ],
            pinnedIcon: { color: primaryColor },
            pinnedText: { fontFamily }
          },
          // No notificaations screen styles
          notificationsContainer: {
            noNotificationsText: { fontFamily },
            noNotificationsSubtext: { fontFamily }
          },
          // Toast styles
          toast: {
            headerText: { fontFamily },
            bodyText: { fontFamily }
          }
        }}
      />
    </div>
  );
};

import { useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { Field, getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import classnames from 'classnames';

import { Button, Typography } from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';

import { readUploadedFileAsText } from 'src/common/fileReader';

import { GALLERY_TYPE } from 'src/pages/Gallery/constants';

import RenderDropzone from 'src/components/ReduxForm/RenderDropzone';

const PREFIX = 'ImportBlueprint';

const classes = {
  container: `${PREFIX}-container`,
  dropzoneContainer: `${PREFIX}-dropzoneContainer`,
  dragDropText: `${PREFIX}-dragDropText`,
  fileContainer: `${PREFIX}-fileContainer`,
  fileIcon: `${PREFIX}-fileIcon`,
  dragDropTextSuccess: `${PREFIX}-dragDropTextSuccess`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(2)} 0`
  },

  [`& .${classes.dropzoneContainer}`]: {
    width: '100%',
    height: '300px',
    border: `5px dashed ${theme.palette.grey[500]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },

  [`& .${classes.dragDropText}`]: {
    fontWeight: 'bold',
    fontSize: '30px',
    marginBottom: theme.spacing(3)
  },

  [`& .${classes.fileContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },

  [`& .${classes.fileIcon}`]: {
    height: '100px',
    width: '100px',
    marginBottom: theme.spacing(3),
    color: theme.palette.success.main
  },

  [`& .${classes.dragDropTextSuccess}`]: {
    color: theme.palette.success.main
  }
}));

const pageText = () => ({
  dragDrop: t('admin:blueprintBuilder.importExportDragDrop'),
  remove: t('admin:blueprintBuilder.importExportRemoveButton'),
  import: t('admin:blueprintBuilder.importExportImportButton'),
  success: t('admin:blueprintBuilder.importExportSuccessMessage')
});

const ImportBlueprint = props => {
  const { formValues, change, formName, onClose } = props;
  const text = useMemo(() => pageText(), []);
  const inputRef = useRef();
  const fileUploaded = !!formValues?.importDocument;

  const handleSubmit = async file => {
    if (file) {
      try {
        const fileContents = await readUploadedFileAsText(file[0]);
        const fileContentsParsed = await JSON.parse(fileContents);
        // these are unique so we don't want to set them on import
        delete fileContentsParsed.document.id;
        delete fileContentsParsed.document.architectureId;
        delete fileContentsParsed.document.slug;
        delete fileContentsParsed.document.organization;
        delete fileContentsParsed.document.architecture;
        delete fileContentsParsed.document.blueprint.slug;

        change(formName, 'importDocument', fileContentsParsed);
        onClose();
      } catch {
        // nothing to update if we failed to read file
        change(formName, 'importDocument', '');
      }
    }
  };

  const clearUpload = () => {
    change(formName, 'importDocument', '');
  };

  return (
    <Root className={classes.container}>
      <Field
        component={RenderDropzone}
        galleryType={GALLERY_TYPE.blueprint}
        inputRef={inputRef}
        name="importDocument"
        containerClass={classes.dropzoneContainer}
        customOnChange={handleSubmit}
      >
        <Typography
          className={classnames(classes.dragDropText, {
            [classes.dragDropTextSuccess]: fileUploaded
          })}
        >
          {fileUploaded ? text.success : text.dragDrop}
        </Typography>
        {fileUploaded ? (
          <div className={classes.fileContainer}>
            <DescriptionIcon className={classes.fileIcon} />
            <Button
              color="primary"
              onClick={clearUpload}
              variant="contained"
              startIcon={<DeleteIcon />}
            >
              {text.remove}
            </Button>
          </div>
        ) : (
          <Button
            color="primary"
            onClick={() => inputRef.current.click()}
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            {text.import}
          </Button>
        )}
      </Field>
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const formValues = getFormValues(formName)(state);
  return {
    formValues
  };
};

export default flow(connect(mapStateToProps, { change }))(ImportBlueprint);

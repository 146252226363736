/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactNode, useRef } from 'react';
import { noop } from 'lodash';
import { WrappedFieldProps, change as changeAction } from 'redux-form';
import { connect } from 'react-redux';
import {
  ControllerRenderProps,
  FieldValues,
  UseFormReturn
} from 'react-hook-form';

import {
  TextField,
  InputAdornment,
  Tooltip,
  FormControl,
  Box
} from '@mui/material';

import HelpIcon from '@mui/icons-material/HelpOutline';

import { AiSuggestionIcon } from 'src/components/AiSuggestion/AiSuggestionIcon';
import { EmojiPickerIcon } from 'src/components/EmojiPicker';
import { validationAllowsEmoji } from 'src/components/EmojiPicker/helpers';
import { useIsProgramOrAutomationPage } from 'src/routes/useIsProgramOrAutomationPage';
import { HelperTextFooter } from 'src/components/ReduxForm/HelperTextFooter';
import { ErrorFooter } from 'src/components/ReduxForm/ErrorFooter';
import {
  AiTextFieldSx,
  AiTextFooter
} from 'src/components/AiSuggestion/AiTextFooter';
import {
  createHandleApplyAiText,
  createHandleFooterAiTextChange,
  resetAiTextAnalysisField
} from 'src/components/AiSuggestion/utils';

import { BusinessObject } from 'src/common/businessObjects';
import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';
import CharacterCountAdornment from './CharacterCountAdornment';
import AiChatAdCopywriterIcon from '../AiChat/AiChatAdCopywriterIcon';

interface RenderTextFieldProps extends WrappedFieldProps {
  children?: ReactNode;
  margin?: 'none' | 'dense' | 'normal';
  variant?: 'standard' | 'filled' | 'outlined';
  businessObjects?: BusinessObject[];
  userMetadataFields?: Record<string, any>[];
  tooltip?: string;
  helperText?: string;
  type?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  readOnly?: boolean;
  stringMaxLength?: number;
  allowAiGeneration?: boolean;
  blueprintVariableId?: string;
  productId?: string;
  adornmentDisablePointerEvents?: boolean;
  validationTypes?: string[];
  displayName?: string;
  isInAiModal?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  parentFormName?: string;
  parentFieldName?: string;
  aiTextAnalysisValues?: any;
  formName?: string;
  change: (form: string, field: string, value: any) => void;
  blockEmojiPicker?: boolean;
  hookFormField?: ControllerRenderProps<FieldValues, string>;
  hookFormContext?: UseFormReturn<FieldValues, any, undefined>;
  isAdCopySuggestionMessageInput?: boolean;
  showAiChatInputIconButton?: boolean;
  aiChat?: {
    toggleAiChatWindow?: () => void;
    aiChatOpen?: boolean;
  };
  productInputFieldId?: string;
  isHighlighted?: boolean;
  label?: string | JSX.Element;
  autoComplete?: string;
  disabled?: boolean;
}

const RenderTextField = (props: RenderTextFieldProps) => {
  const {
    children,
    input,
    margin = 'normal',
    meta: { touched = false, error } = {},
    variant = 'outlined',
    businessObjects = [], // was causing a warning when getting passed with ...rest
    userMetadataFields,
    tooltip,
    helperText,
    type,
    startAdornment,
    endAdornment,
    readOnly = false,
    stringMaxLength,
    allowAiGeneration,
    blueprintVariableId,
    productId,
    adornmentDisablePointerEvents = true,
    validationTypes,
    displayName = '',
    isInAiModal = false,
    fullWidth,
    multiline,
    parentFormName,
    parentFieldName,
    aiTextAnalysisValues,
    formName,
    change: reduxChange,
    blockEmojiPicker = false,
    hookFormField,
    hookFormContext,
    isAdCopySuggestionMessageInput = false,
    showAiChatInputIconButton = false,
    aiChat = {},
    isHighlighted,
    label,
    autoComplete,
    disabled
  } = props;

  const change = getChangeFormValue({
    reduxChange,
    hookSetValue: hookFormContext?.setValue
  });

  const showCharacterCount = !!stringMaxLength;

  const handleAiTextChange = (aiValue: string) => {
    input.onChange(aiValue);

    if (allowAiGeneration && aiValue.trim() === '' && !!aiTextAnalysisValues) {
      resetAiTextAnalysisField({
        change,
        formName: parentFormName || formName!,
        inputName: parentFieldName || input.name
      });
    }
  };

  const isOnProgramOrAutomationPage = useIsProgramOrAutomationPage();
  const allowEmoji =
    !blockEmojiPicker && validationAllowsEmoji(validationTypes);
  const showEmojiPicker = isOnProgramOrAutomationPage && allowEmoji;

  const inputRef = useRef<HTMLInputElement>();
  const combinedRef = (node: HTMLInputElement) => {
    if (node !== null) {
      inputRef.current = node;

      if (hookFormField?.ref) {
        hookFormField.ref(node);
      }
    }
  };

  /**
   * Stores the last range of the input so we can insert the emoji
   * in the right location, potentially replacing text
   * @type {React.MutableRefObject<{selectionStart: number, selectionEnd: number} | null>}
   */
  const lastInputRange = useRef<{
    selectionStart: number;
    selectionEnd: number;
  } | null>();

  const handleEmojiPanelOpen = () => {
    if (inputRef.current == null) {
      lastInputRange.current = null;
      return;
    }
    lastInputRange.current = {
      selectionStart: inputRef.current.selectionStart!,
      selectionEnd: inputRef.current.selectionEnd!
    };
  };

  const handleEmojiSelected = (emoji: string) => {
    if (lastInputRange.current == null) {
      // If for whatever eason we don't have a range
      // just default to the end of the input since that always works
      input.onChange((input.value as string) + emoji);
    } else {
      // If we have a range then we need to replace the text
      // in the range with the emoji
      const { selectionStart, selectionEnd } = lastInputRange.current;
      const valuePart1 = input.value.slice(0, selectionStart) as string;
      const valuePart2 = input.value.slice(selectionEnd) as string;
      input.onChange(valuePart1 + emoji + valuePart2);
      // Now that we've changed the selection range we need to update
      // the range to be at the end of the emoji
      lastInputRange.current = {
        selectionStart: selectionStart + emoji.length,
        selectionEnd: selectionStart + emoji.length
      };
    }
  };

  const aiModalTextFieldProps = {
    margin,
    variant,
    businessObjects,
    userMetadataFields,
    tooltip,
    helperText,
    type,
    startAdornment,
    endAdornment,
    readOnly,
    stringMaxLength,
    blueprintVariableId,
    productId,
    adornmentDisablePointerEvents,
    validationTypes,
    displayName,
    fullWidth,
    multiline,
    // prevent infinite loops since this is inside the AI generator modal
    allowAiGeneration: false,
    // But since this will be in the modal we need to mark it as such
    isInAiModal: true
  };

  const handleFooterAiTextChange = createHandleFooterAiTextChange({
    callback: handleAiTextChange,
    formName: parentFormName || formName!,
    inputName: parentFieldName || input.name,
    change
  });

  const handleApplyAiText = createHandleApplyAiText({
    callback: handleAiTextChange,
    formName: parentFormName || formName!,
    inputName: parentFieldName || input.name,
    change
  });

  const length = input?.value?.length || 0;

  const handleAiChatAdCopywriterClick = () => {
    if (aiChat?.toggleAiChatWindow && !aiChat?.aiChatOpen) {
      aiChat.toggleAiChatWindow();
    }
  };

  const inputInError = touched && !!error;

  return (
    <FormControl fullWidth>
      <TextField
        fullWidth={fullWidth}
        multiline={multiline}
        error={touched && !!error}
        type={type}
        variant={readOnly ? 'standard' : variant}
        disabled={disabled}
        InputLabelProps={{
          ...(readOnly && {
            shrink: true
          })
        }}
        sx={isInAiModal ? AiTextFieldSx : {}}
        inputRef={combinedRef}
        InputProps={{
          ...(readOnly && {
            readOnly: true,
            disableUnderline: true
          }),
          sx: {
            ...(isHighlighted && {
              '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: theme => theme.palette.primary.main
              },
              '& fieldset': {
                borderColor: theme => theme.palette.primary.main,
                borderWidth: '2px'
              }
            })
          },
          // Note: Type is how we determine if the the input is hidden && the
          //       endAdornment is showing up even when the input is hidden.
          ...(type !== 'hidden' && {
            endAdornment: (
              <>
                {endAdornment && (
                  <InputAdornment
                    sx={{ position: 'relative', right: '5px' }}
                    disablePointerEvents={adornmentDisablePointerEvents} // This allows the adornment to focus the input on click
                    position="end"
                  >
                    {endAdornment}
                  </InputAdornment>
                )}
                {tooltip && (
                  <InputAdornment
                    sx={{
                      color: theme => theme.palette.grey[500],
                      position: 'absolute',
                      top: '13px',
                      right: '3px'
                    }}
                    position="end"
                  >
                    <Tooltip arrow title={tooltip}>
                      <HelpIcon sx={{ width: '20px' }} />
                    </Tooltip>
                  </InputAdornment>
                )}
                {showCharacterCount && (
                  <InputAdornment
                    sx={{
                      position: 'absolute',
                      bottom: '11px',
                      right: '5px'
                    }}
                    position="end"
                  >
                    <CharacterCountAdornment
                      length={length}
                      stringMaxLength={stringMaxLength}
                    />
                  </InputAdornment>
                )}
              </>
            )
          }),
          // Note: Type is how we determine if the the input is hidden && the
          //       startAdornment is showing up even when the input is hidden.
          ...(type !== 'hidden' &&
            startAdornment && {
              startAdornment: (
                <InputAdornment
                  disablePointerEvents={adornmentDisablePointerEvents} // This allows the adornment to focus the input on click
                  position="start"
                >
                  {startAdornment}
                </InputAdornment>
              )
            })
        }}
        label={label}
        autoComplete={autoComplete}
        {...input}
      >
        {children && children}
      </TextField>
      {isInAiModal && !isAdCopySuggestionMessageInput && (
        <AiTextFooter
          onAiTextChange={handleFooterAiTextChange}
          blueprintVariableId={blueprintVariableId!}
          catalogItemId={businessObjects[0]?.id}
          productId={productId!}
          currentValue={input.value}
          storeGenAiText={noop}
        />
      )}
      {(allowAiGeneration ||
        showEmojiPicker ||
        helperText ||
        error ||
        showAiChatInputIconButton) &&
        !isAdCopySuggestionMessageInput && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            <Box sx={{ flex: 1 }}>
              {inputInError && <ErrorFooter touched={touched} error={error} />}
              {helperText && (
                <HelperTextFooter
                  helperText={helperText}
                  stacked={inputInError}
                />
              )}
            </Box>
            <Box>
              {showEmojiPicker && (
                <EmojiPickerIcon
                  onEmojiPanelOpen={handleEmojiPanelOpen}
                  onEmojiSelected={handleEmojiSelected}
                />
              )}
              {showAiChatInputIconButton && (
                <AiChatAdCopywriterIcon
                  onClick={handleAiChatAdCopywriterClick}
                />
              )}
              {allowAiGeneration && (
                <AiSuggestionIcon
                  onAiTextChange={handleApplyAiText}
                  initialValue={input.value}
                  displayName={displayName}
                  fieldName={`${input.name}-ai`}
                  textField={{
                    render: RenderTextField as any,
                    props: aiModalTextFieldProps
                  }}
                  parentFieldName={input.name}
                  parentFormName={formName!}
                  hookFormContext={hookFormContext}
                  change={change}
                />
              )}
            </Box>
          </Box>
        )}
    </FormControl>
  );
};

export default connect(null, { change: changeAction })(RenderTextField);

import { sortBy, isEmpty, merge, keys, some } from 'lodash';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

import { Grid } from '@mui/material';

import { DynamicForm, FormSection } from 'src/components/ReduxForm';
import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';

import { getOneOfEachChannelKeys } from 'src/common/blueprints';
import { PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME } from './Constants';

const PREFIX = 'ProgramDynamicUserInputs';

const classes = {
  hiddenSection: `${PREFIX}-hiddenSection`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.hiddenSection}`]: {
    display: 'none'
  }
}));

const ProgramDynamicUserInputs = ({
  blueprint,
  contentColumns,
  dynamicUserInputSections,
  businessObjects = [],
  formName,
  isContentSelectable,
  contentName,
  adCreativeErrors,
  formSyncErrors,
  formAsyncErrors,
  isChannelValidationLoading,
  channelInputValidators,
  formSectionName = PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME,
  isHookForm,
  hookFormMethods,
  selectedLocationsMetadata = [],
  isMultiLocation,
  aiAdCopyWriterInputs,
  aiChat,
  highlightedInputs,
  conditionalInputsVisibility
}) => {
  const isProgramCreatePage = useIsProgramCreatePage();

  return (
    <StyledGrid
      container
      gap={isProgramCreatePage ? 2 : 4}
      id="dynamic-user-inputs"
    >
      {sortBy(
        dynamicUserInputSections,
        inputSection => inputSection?.displaySortOrder
      ).map(dynamicUserInputSection => {
        const disId = dynamicUserInputSection?.sectionId;
        const dynamicUserInputs = dynamicUserInputSection?.inputFields || [];

        const isCollapsed = dynamicUserInputSection?.isCollapsed;
        const isHidden = dynamicUserInputSection?.isHidden;

        let sectionHasError = false;
        if (
          !isEmpty(adCreativeErrors) ||
          !isEmpty(formSyncErrors) ||
          !isEmpty(formAsyncErrors)
        ) {
          // get the field names from all errors
          const allErrorInputs = new Set(
            keys(
              merge(
                {},
                formSyncErrors || {},
                formAsyncErrors || {},
                adCreativeErrors || {}
              )[PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME] || {}
            )
          );
          sectionHasError = some(dynamicUserInputs, input =>
            allErrorInputs.has(input.fieldName)
          );
        }

        const dynamicFormProps = {
          inputs: dynamicUserInputs,
          contentColumns,
          businessObjects,
          blueprint,
          formName,
          isContentSelectable,
          contentName,
          adCreativeErrors,
          isChannelValidationLoading,
          channelInputValidators,
          isHookForm,
          hookFormMethods,
          formSectionName,
          selectedLocationsMetadata,
          isMultiLocation,
          aiAdCopyWriterInputs,
          aiChat,
          highlightedInputs,
          conditionalInputsVisibility
        };

        return (
          <Grid
            key={`dis-${disId}`}
            item
            xs={12}
            className={classNames({ [classes.hiddenSection]: isHidden })}
          >
            {!isHidden && (
              <FormSection
                title={dynamicUserInputSection?.name}
                description={dynamicUserInputSection?.description}
                isCollapsed={isCollapsed}
                helpText={dynamicUserInputSection?.helpText}
                isContentSelectable={isContentSelectable}
                businessObjects={businessObjects}
                productId={blueprint?.id}
                channel={getOneOfEachChannelKeys(
                  blueprint?.blueprint?.channels
                )}
                sectionHasError={sectionHasError}
              >
                <DynamicForm {...dynamicFormProps} />
              </FormSection>
            )}
          </Grid>
        );
      })}
    </StyledGrid>
  );
};

export default ProgramDynamicUserInputs;

import { flow } from 'lodash';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import { cld } from 'src/common/cloudinaryUtils';
import { trim } from '@cloudinary/url-gen/actions/videoEdit';
import { AdvancedImage } from '@cloudinary/react';

const PREFIX = 'VideoThumbnail';

const classes = {
  asset: `${PREFIX}-asset`,
  videoAsset: `${PREFIX}-videoAsset`,
  lightboxAsset: `${PREFIX}-lightboxAsset`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.asset}`]: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },

  [`& .${classes.videoAsset}`]: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },

  [`& .${classes.lightboxAsset}`]: {
    objectFit: 'contain'
  }
}));

const getCloudinaryVideoThumbnail = sourceUrl => {
  return cld
    .video(sourceUrl)
    .videoEdit(trim().startOffset(5.0))
    .setAssetType('video')
    .setDeliveryType('fetch')
    .format('jpg');
};

const VideoThumbnail = props => {
  const { asset, sourceUrl } = props;

  return (
    <Root>
      <AdvancedImage
        alt={asset?.id || asset}
        className={classnames(classes.asset)}
        cldImg={getCloudinaryVideoThumbnail(sourceUrl)}
        data-cy={`gallery-asset-${asset?.id || asset}`}
      />
    </Root>
  );
};

export default flow()(VideoThumbnail);
